import {
  UPDATE_COUPON_REQUEST,
  COUPON_BOOK_REQUEST,
  VERIFY_TYPE_REQUEST,
  CREATE_CAMPAIGN_LOADING,
  COUPON_DETAIL_RESPONSE,
  UPDATE_TO_INITIAL_VALUE,
  CAMPAIGN_STATUS_DROPDOWN,
  UPDATE_CAMPAIGN_ID,
  SAVE_STATUS,
  SENDER_LIST_REQUEST,
  UPDATE_SENDER_REQUEST 
} from "../actions/types"

const initialState = {
  createCouponRequest: {
    information: {
      couponId: 0,
      couponImg: "",
      couponImagePreview: "",
      couponName: "",
      checkedRedeemBtn: true,
      buttonText: "REDEEM",
      textColor: "#FFFFFF",
      buttonColor: "#01A9F4",
      condition: null
    },
    smsSetting: {
      senderName: "",
      message: "",
      description: "",
      sender: null
    },
    setting: {
      expireCoupon: false,
      startDate: "",
      endDate: "",
      createDate: "",
      couponBook: null,
      couponCodePrefix: "",
      useMax: "",
      useMaxRedeem: "",
      verifyType: null,
      status: "",
      oneTimeCoupon: false,
      shortenUrl: false
    },
    reference: {
      smsCallbackURL: "",
      redeemCallbackURL: "",
      reference: "",
      reference2: "",
      reference3: "",
      reference4: ""
    }
  },
  couponBookList: [],
  verifyTypeList: [],
  campaignStatusList: [],
  loading: false,
  saveStatus: false,
  senderList: []
}

const mergeState = (statePart, actionPart) => {
  return Object.keys(actionPart).reduce((newState, key) => {
    newState[key] = actionPart[key]
    return newState
  }, { ...statePart })
}

const createCoupon = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SAVE_STATUS:
      return {
        ...state,
        saveStatus: payload
      }
    case UPDATE_CAMPAIGN_ID:
      return {
        ...state,
        createCouponRequest: {
          ...state.createCouponRequest, 
            information: {
              ...state.information,
              couponId: payload
            }
        }
      }
    case CAMPAIGN_STATUS_DROPDOWN:
      return {
        ...state,
        campaignStatusList: payload
      }
    case UPDATE_TO_INITIAL_VALUE:
      return {
        ...state,
        createCouponRequest: initialState.createCouponRequest
      }
    case COUPON_DETAIL_RESPONSE:
      return {
        ...state,
        createCouponRequest: {
          ...state.createCouponRequest,
          information: mergeState(state.createCouponRequest.information, payload.information),
          smsSetting: mergeState(state.createCouponRequest.smsSetting, payload.smsSetting),
          setting: mergeState(state.createCouponRequest.setting, payload.setting),
          reference: mergeState(state.createCouponRequest.reference, payload.reference)
        }
      }
    case CREATE_CAMPAIGN_LOADING:
      return {
        ...state,
        loading: payload
      }
    case UPDATE_COUPON_REQUEST:
      return {
        ...state,
        createCouponRequest: {
          ...state.createCouponRequest,
          [payload.type]: {
            ...state.createCouponRequest[payload.type],
            [payload.name]: payload.value
          }
        }
      }
    case VERIFY_TYPE_REQUEST:
      return {
        ...state,
        verifyTypeList: payload
      }
    case COUPON_BOOK_REQUEST:
      return {
        ...state,
        couponBookList: payload
      }
    case SENDER_LIST_REQUEST:
      return {
        ...state,
        senderList: payload
      }
    case UPDATE_SENDER_REQUEST:
      return {
        ...state,
        smsSetting: {
          ...state.smsSetting,
          [payload.name]: payload.value
        }
      }
    default:
      return state
  }
}

export default createCoupon